import React, {useState} from "react";
import "./TextInput.css";
import {Typography} from "@mui/material";

const FloatingLabelInput = ({
	handleChange,
	value,
	Placeholder,
	name,
	type,
	validate,
}) => {
	const [isFocused, setIsFocused] = useState(false);

	const handleFocus = () => setIsFocused(true);
	const handleBlur = () => setIsFocused(value !== "");

	return (
		<div className="floating-label-input">
			<label className={isFocused || value ? "label-focused" : ""}>
				{Placeholder}
			</label>
			<input
				type={type}
				name={name}
				value={value}
				onFocus={handleFocus}
				onBlur={handleBlur}
				onChange={handleChange}
				autoComplete="off" // Disable autofill/autocomplete
			/>
			<Typography
				sx={{
					color: "red",
					fontSize: "12px",
					paddingLeft: "12px",
					paddingTop: "6px",
				}}
			>
				{validate}
			</Typography>
		</div>
	);
};

export default FloatingLabelInput;
